import { render, staticRenderFns } from "./default.vue?vue&type=template&id=82b812c8&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=82b812c8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.1_mi7kfaeh4nmupiqclubmvzt3ru/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeader: require('/opt/buildhome/repo/components/Base/Header.vue').default,FlashyBg: require('/opt/buildhome/repo/components/FlashyBg.vue').default,Breadcrumbs: require('/opt/buildhome/repo/components/Breadcrumbs.vue').default,ArticlesHeader: require('/opt/buildhome/repo/components/ArticlesHeader.vue').default,Articles: require('/opt/buildhome/repo/components/Articles.vue').default,CarouselBadutMaskotMini: require('/opt/buildhome/repo/components/Carousel/BadutMaskotMini.vue').default,Kontak: require('/opt/buildhome/repo/components/Kontak.vue').default,BaseFooter: require('/opt/buildhome/repo/components/Base/Footer.vue').default,FloatingWhatsapp: require('/opt/buildhome/repo/components/FloatingWhatsapp.vue').default,MainMenu: require('/opt/buildhome/repo/components/MainMenu.vue').default,IosInstallation: require('/opt/buildhome/repo/components/IosInstallation.vue').default})
