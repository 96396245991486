import { render, staticRenderFns } from "./WhatsappButton.vue?vue&type=template&id=59817e82&"
import script from "./WhatsappButton.vue?vue&type=script&lang=ts&"
export * from "./WhatsappButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.1_mi7kfaeh4nmupiqclubmvzt3ru/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports