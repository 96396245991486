
import jQuery from "jquery";

export default {
  data() {
    return {
      jQuery: jQuery,
    };
  },
  watch: {
    $route() {
      this.$nextTick(this.routeLoaded);
    },
  },
  methods: {
    routeLoaded() {
      jQuery("#main-menu").foundation("close");
    },
  },
};
