
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alt: "",
      title: "",
    };
  },
  mounted() {
    const $t = this.$t.bind(this);
    const caption = this.blok.caption;

    this.alt += $t("dollMascotCostume", [caption]);
    this.title = $t("fullSizePhoto", [caption]);
  },
};
