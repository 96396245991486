
import jQuery from "jquery";

export default {
  data() {
    return {
      jQuery: jQuery,
    };
  },
};
