
export default {
  props: {
    parentLink: {
      default: "",
    },
    childLink: {
      default: "",
    },
    theParent: {
      default: "",
    },
    theChild: {
      default: "",
    },
  },
  data() {
    const $t = this.$t.bind(this);

    return {
      home: $t("home"),
    };
  },
};
