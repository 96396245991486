import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=3e8a7d32&"
import script from "./Menu.vue?vue&type=script&lang=ts&"
export * from "./Menu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_mi7kfaeh4nmupiqclubmvzt3ru/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconFlagId: require('/opt/buildhome/repo/components/Icon/FlagId.vue').default,IconFlagEn: require('/opt/buildhome/repo/components/Icon/FlagEn.vue').default,HeaderHamburger: require('/opt/buildhome/repo/components/Header/Hamburger.vue').default})
