
export default {
  name: "InstallButton",
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      deferredPrompt: null,
    };
  },
  computed: {
    isReveal: function () {
      return this.id === "ios" ? "ios-installation" : "";
    },
  },
  created() {
    if (process.client) {
      if (!this.$device.isIos) {
        window.addEventListener("beforeinstallprompt", (e) => {
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e;
        });
        window.addEventListener("appinstalled", () => {
          this.deferredPrompt = null;
        });
      }
    }
  },
  methods: {
    onlyOn(id) {
      return `onlyOn.${id}`;
    },
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      if (this.deferredPrompt !== null) {
        this.deferredPrompt.prompt();
      }
    },
  },
};
