
import { directive } from "vue-awesome-swiper";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";

export default {
  name: "IosInstallation",
  directives: {
    swiper: directive,
  },
  data() {
    return {
      photos: [
        "",
        "https://a.storyblok.com/f/118728/600x1204/70261e506a/1.png",
        "https://a.storyblok.com/f/118728/600x1204/b7eb53f2ad/2.png",
        "https://a.storyblok.com/f/118728/600x1204/db2807dd44/3.png",
        "https://a.storyblok.com/f/118728/600x1204/1bce936a87/4.png",
      ],
      items: [
        this.$t("iosInstallation.share"),
        this.$t("iosInstallation.addToHomeScreen"),
        this.$t("iosInstallation.add"),
        this.$t("iosInstallation.voila"),
      ],
      swiperOption: {
        pagination: {
          el: "#swiper-pagination",
          type: "bullets",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        grabCursor: true,
        centerInsufficientSlides: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        slidesPerView: "1",
        spaceBetween: 8,
        loop: false,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        slidesOffsetBefore: "6",
        mousewheel: {
          invert: false,
          forceToAxis: true,
        },
      },
    };
  },
};
