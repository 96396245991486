
export default {
  name: "FloatingWhatsApp",
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      footerHeight: 0,
      classHide: "translate-y-20 lg:translate-y-[626px] shadow-none",
      classShow: "translate-x-0 shadow-xl",
    };
  },
};
