import Vue from "vue";

import Page from "~/components/Portfolio/Page.vue";
import Grid from "~/components/Portfolio/Grid.vue";
import Photo from "~/components/Portfolio/Photo.vue";

import CarouselBadutMaskot from "~/components/Carousel/BadutMaskot.vue";
import CarouselPhoto from "~/components/Carousel/CarouselPhoto.vue";

Vue.component("Page", Page);
Vue.component("Grid", Grid);
Vue.component("Photo", Photo);

Vue.component("Carousel", CarouselBadutMaskot);
Vue.component("Carouselphoto", CarouselPhoto);
