import { render, staticRenderFns } from "./MainMenu.vue?vue&type=template&id=26e6c01e&scoped=true&"
import script from "./MainMenu.vue?vue&type=script&lang=ts&"
export * from "./MainMenu.vue?vue&type=script&lang=ts&"
import style0 from "./MainMenu.vue?vue&type=style&index=0&id=26e6c01e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.1_mi7kfaeh4nmupiqclubmvzt3ru/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26e6c01e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlashyBg: require('/opt/buildhome/repo/components/FlashyBg.vue').default,IconClose: require('/opt/buildhome/repo/components/Icon/Close.vue').default,InstallButton: require('/opt/buildhome/repo/components/InstallButton.vue').default,IconFlagId: require('/opt/buildhome/repo/components/Icon/FlagId.vue').default,IconFlagEn: require('/opt/buildhome/repo/components/Icon/FlagEn.vue').default})
