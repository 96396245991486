import jQuery from "jquery";

import Vue from "vue";

import "foundation-sites";

import "featherlight";

import "lite-youtube-embed";

import Fragment from "vue-fragment";

import {
  Swiper as SwiperClass,
  Mousewheel,
  Autoplay,
  Navigation,
  Pagination,
  EffectCoverflow,
} from "swiper/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
SwiperClass.use([
  Mousewheel,
  Autoplay,
  Navigation,
  Pagination,
  EffectCoverflow,
]);
Vue.use(getAwesomeSwiper(SwiperClass));
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

Vue.directive("f-reveal", {
  bind: function (el) {
    new Foundation.Reveal(jQuery(el));
  },
  unbind: function (el) {
    jQuery(el).foundation.destroy();
  },
});

Vue.use(Fragment.Plugin);
