
export default {
  layout: "default",
  props: ["error"], // you can set a custom layout for the error page

  data() {
    return {
      title: "404 | INDOMASCOT",
    };
  },
  head() {
    return {
      title: this.title,
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: "404",
          name: "404",
          content: this.$t("pageNotFound"),
        },
      ],
    };
  },
};
