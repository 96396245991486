
import startCase from "lodash.startcase";

export default {
  name: "DefaultLayout",
  data() {
    return {
      showMainHeaderBgWhite: false,
      hideFloatingWaButton: false,
      borderHeader: false,
      intersectionOptions2: [],
      intersectionOptions: [],
      observer: null,
      observer2: null,
      entries: null,
      theParent: "",
      theChild: "",
      parentLink: "",
      theBreadcrumbs: {},
      structuredData: [],
    };
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      meta: [
        { hid: "charset", charset: "utf-8" },
        {
          hid: "viewport",
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          hid: "http-equiv-prefetch-dns",
          "http-equiv": "x-dns-prefetch-control",
          content: "on",
        },
        {
          hid: "http-equiv-x-ua-compatible",
          "http-equiv": "X-UA-Compatible",
          content: "IE=edge",
        },
        { hid: "foundation-mq", class: "foundation-mq" },
        ...i18nHead.meta,
      ],
      link: [
        {
          rel: "mask-icon",
          href: "/safari-pinned-tab.svg",
          color: "#003399",
        },
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/apple-touch-icon.png",
        },
        {
          rel: "prefetch",
          href: "/loading-indicator.svg",
        },
        { rel: "preconnect", href: "https://a.storyblok.com/" },
        { rel: "dns-prefetch", href: "https://a.storyblok.com/" },
        { rel: "dns-prefetch", href: "https://fonts.gstatic.com/" },
        {
          rel: "preconnect",
          href: "https://fonts.gstatic.com/",
          crossorigin: "",
        },
        { rel: "preconnect", href: "https://fonts.googleapis.com/" },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css2?family=Bangers:wght@400&family=Josefin+Sans:wght@100;300;400;600;700&family=Inter:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap",
        },
        ...i18nHead.link,
      ],
      script: [{ type: "application/ld+json", json: this.structuredData }],
    };
  },
  watch: {
    $route: function (route) {
      this.watchRoute(route);
      this.$nextTick(this.observeOnLeaveViewport);
    },
  },
  beforeUnmount() {
    this.observer.disconnect();
    this.observer2.disconnect();
  },
  mounted() {
    this.watchRoute($nuxt.$route);

    this.intersectionOptions = {
      threshold: [0, 1],
    };
    this.observer = new IntersectionObserver(
      this.onIntersecting,
      this.intersectionOptions
    );
    this.observer.observe(document.getElementById("kontak"));
    this.observer.observe(document.getElementById("maskot-footer"));

    this.observeOnLeaveViewport();
  },
  methods: {
    calculateStructuredData() {
      // console.log(this);
      // if it's homepage
      if (this.getRouteBaseName() === "index") {
        this.theBreadcrumbs = {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: this.$t("home"),
              id: "https://www.indomascot.com" + this.localePath("/"),
              item: "https://www.indomascot.com" + this.localePath("/"),
            },
          ],
        };
        // if it's an article
      } else if (this.theParent) {
        this.theBreadcrumbs = {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: this.$t("home"),
              id: "https://www.indomascot.com" + this.localePath("/"),
              item: "https://www.indomascot.com" + this.localePath("/"),
            },
            {
              "@type": "ListItem",
              position: 2,
              name: this.theParent,
              id:
                "https://www.indomascot.com" + this.localePath(this.parentLink),
              item:
                "https://www.indomascot.com" + this.localePath(this.parentLink),
            },
            {
              "@type": "ListItem",
              position: 3,
              name: this.theChild,
              id:
                "https://www.indomascot.com" +
                this.localePath(this.getRouteBaseName()),
            },
          ],
        };
        // if it's a page
      } else {
        this.theBreadcrumbs = {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: this.$t("home"),
              id: "https://www.indomascot.com" + this.localePath("/"),
              item: "https://www.indomascot.com" + this.localePath("/"),
            },
            {
              "@type": "ListItem",
              position: 2,
              name: this.theChild,
              id:
                "https://www.indomascot.com" +
                this.localePath(this.getRouteBaseName()),
            },
          ],
        };
      }
      this.structuredData = [
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          url: "https://www.indomascot.com/",
          potentialAction: {
            "@type": "SearchAction",
            target: {
              "@type": "EntryPoint",
              urlTemplate:
                "https://www.indomascot.com/pencarian?q={search_term_string}",
            },
            "query-input": "required name=search_term_string",
          },
        },
        this.theBreadcrumbs,
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "INDOMASCOT logo",
          url: "https://www.indomascot.com",
          logo: "https://www.indomascot.com/indomascot_logo_gradient.svg",
        },
        {
          "@context": "https://schema.org",
          "@type": "Store",
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: "4.1",
            reviewCount: "12",
          },
          image: [
            "https://a.storyblok.com/f/118728/1400x1400/ba97dc3514/kostum-badut-maskot-burger-bangor-man.jpg/m/628x628",
            "https://a.storyblok.com/f/118728/1400x1400/122889d837/kostum-badut-maskot-roti-kapiten.jpg/m/628x628",
            "https://a.storyblok.com/f/118728/1400x1400/f6a29df146/kostum-badut-maskot-unilabs.jpg/m/628x628",
            "https://a.storyblok.com/f/118728/1400x1400/4ab74e83a1/kostum-badut-grab-jastip-motor-lagi.jpg/m/628x628",
            "https://a.storyblok.com/f/118728/1400x1400/92e6aec87c/kostum-badut-dr-rara-rs-lira-medika.jpg/m/628x628",
            "https://a.storyblok.com/f/118728/1400x1400/a2e11fad8c/kostum-badut-maskot-oppa-bowl.jpg/m/628x628",
          ],
          name: "INDOMASCOT",
          address: {
            "@type": "PostalAddress",
            streetAddress:
              "Jl. Cukang Kawung Gang Bojong Mekar No. 53 RT 01 RW 15 Cibeunying Kaler, Kec. Cimenyan",
            addressLocality: "Bandung",
            addressRegion: "BDO",
            postalCode: "40191",
            addressCountry: "ID",
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: -6.886996,
            longitude: 107.6340354,
          },
          url: "https://www.indomascot.com/",
          telephone: "+6282221556633",
          priceRange: "$$",
          openingHoursSpecification: [
            {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ],
              opens: "08:00",
              closes: "16:30",
            },
          ],
        },
      ];
    },
    observeOnLeaveViewport() {
      // console.log("executed");
      this.intersectionOptions2 = {
        rootMargin: "0px 0px -100%",
        threshold: [0, 1],
      };
      this.observer2 = new IntersectionObserver(
        this.onIntersecting,
        this.intersectionOptions2
      );

      if (this.getRouteBaseName() === "index") {
        this.observer2.observe(document.getElementById("definisi"));
        this.observer2.observe(document.getElementById("portfolio-homepage"));
      } else if (this.getRouteBaseName() === "badut-maskot") {
        this.observer2.observe(
          document.getElementById("portfolio-badut-maskot")
        );
      } else if (this.getRouteBaseName() === "syarat-ketentuan") {
        this.observer2.observe(document.getElementById("syarat-ketentuan"));
      } else if (this.getRouteBaseName() === "tentang-kami") {
        this.observer2.observe(
          document.getElementById(
            "workshop-boneka-maskot-besar-indomascot-bandung"
          )
        );
      }
    },
    onIntersecting: function (entries) {
      // this.entries = entries;
      entries.forEach((entry) => {
        // console.log(entry);
        if (entry.target.id === "kontak") {
          if (entry.isIntersecting) {
            if (entry.intersectionRect.y > 0) {
              this.hideFloatingWaButton =
                0 < entry.intersectionRatio && 1 > entry.intersectionRatio
                  ? true
                  : false;
            }
          } else {
            this.hideFloatingWaButton = false;
          }
        } else if (entry.target.id === "definisi") {
          if (entry.isIntersecting) {
            this.showMainHeaderBgWhite = true;
          } else {
            this.showMainHeaderBgWhite = false;
          }
        } else if (entry.target.id === "maskot-footer") {
          if (entry.isIntersecting) {
            this.showMainHeaderBgWhite = true;
          } else {
            this.showMainHeaderBgWhite = false;
          }
        } else if (entry.target.id === "portfolio-homepage") {
          if (entry.isIntersecting) {
            this.showMainHeaderBgWhite = true;
          } else {
            this.showMainHeaderBgWhite = false;
          }
        } else if (entry.target.id === "portfolio-badut-maskot") {
          if (entry.isIntersecting) {
            this.showMainHeaderBgWhite = true;
          } else {
            this.showMainHeaderBgWhite = false;
          }
        } else if (entry.target.id === "syarat-ketentuan") {
          if (entry.isIntersecting) {
            this.showMainHeaderBgWhite = true;
          } else {
            this.showMainHeaderBgWhite = false;
          }
        } else if (
          entry.target.id === "workshop-boneka-maskot-besar-indomascot-bandung"
        ) {
          if (entry.isIntersecting) {
            this.showMainHeaderBgWhite = true;
          } else {
            this.showMainHeaderBgWhite = false;
          }
        }
      });
    },
    watchRoute: function (route) {
      this.calculateStructuredData();
      if (this.getRouteBaseName() == "blog-slug") {
        this.theParent = "blog";
        this.theChild = route.params.slug;
      } else if (this.getRouteBaseName() == "tag-tag") {
        this.theParent = "tag";
        this.theChild = route.params.tag;
      } else if (route.name === null) {
        this.theParent = false;
        this.theChild = "404";
      } else {
        this.theParent = false;
        this.theChild = this.getRouteBaseName();
      }
      if (this.theParent) {
        this.parentLink = "/" + this.theParent;
        this.theParent = startCase(this.theParent);
      }
      if (this.getRouteBaseName() == "tag-tag") {
        this.theChild = `#${this.theChild}`;
      } else {
        this.theChild = startCase(this.theChild);
        this.theChild = this.theChild.replace(" Id", "");
        this.theChild = this.theChild.replace(" En", "");
        this.theChild = this.theChild.replace(" Default", "");
      }
      if (this.$i18n.locale === "en") {
        switch (this.getRouteBaseName()) {
          case "index":
            this.theChild = this.$t("title");
            break;
          case "badut-maskot":
            this.theChild = this.$t("mascotCostume");
            break;
          case "boneka-custom":
            this.theChild = this.$t("customDoll");
            break;
          case "syarat-ketentuan":
            this.theChild = this.$t("termsAndConditions");
            break;
          case "tentang-kami":
            this.theChild = this.$t("aboutUs");
            break;
          case "cara-pemesanan":
            this.theChild = this.$t("howToOrder");
            break;
          case "pencarian":
            this.theChild = this.$t("search");
            break;
          default:
            break;
        }
      }
    },
  },
};
