export default {
  company: "INDOMASCOT",
  title: "Spesialis Kostum Badut Boneka Maskot Custom",
  titleHero: "Spesialis Kostum Badut Maskot",
  home: "Beranda",
  search: "Pencarian",
  welcome: "Selamat Datang",
  specialist: "Spesialis",
  howToOrderMascotCostume: "Cara Pemesanan Badut Maskot",
  howToOrderCostumDoll: "Cara Pemesanan Boneka Custom",
  aboutUs: "Tentang Kami",
  termsAndConditions: "Syarat & Ketentuan",
  portfolio: "Portfolio",
  customDoll: "Boneka Custom",
  mascotCostume: "Badut Maskot",
  blog: "Artikel",
  generalTermsAndConditions: "Syarat & Ketentuan Umum",
  designCanBeSentVia: "Desain maskot dapat dikirimkan melalui",
  or: "atau",
  paymentTransferredByAccount:
    "Pembayaran ditransfer melalui rekening kami dengan detail sebagai berikut:",
  bankName: "Nama Bank",
  accountHolder: "Nama Pemegang Rekening",
  accountNo: "No Rekening",
  contact: "kontak",
  workshop: "workshop",
  howToOrder: "cara pemesanan",
  forFastResponse: "untuk respons cepat",
  aboutEstimatesEtc: "mengenai surat penawaran dan lain-lainnya, ",
  pleaseContactUs: "mohon hubungi",
  weProduce:
    "Sudah lebih dari ratusan UMKM, startup, komunitas hingga institusi pemerintahan telah memercayakan kami untuk memproduksikan kostum badut maskot mereka dengan desain dari mereka sendiri untuk mensukseskan kegiatan event, sosialiasi, promosi hingga brandingnya.",
  andManyMore: "dan masih banyak lagi",
  mascotCostumeSubtitle:
    "Kostum badut maskot ini berukuran all-size yang artinya proporsional dipakai oleh orang dewasa yang tinggi umumnya 170-190cm.",
  showMore: "lihat selengkapnya",
  potentialCustomer: "potential customer",
  aboutUsParagraph: {
    p1: "Kami ingin bersama-sama anda mendukung pemasaran produk dan layanan perusahaan anda 🤝 🏆",
    p2: "Solusi yang kami tawarkan adalah dengan cara mempersembahkan karya seni berkualitas tinggi dibandingkan kompetitor sejenis dalam bentuk kostum badut maskot untuk mendukung suksesnya target pemasaran perusahaan anda.",
    p3: "Dengan berbekal pengalaman & relasi yang lebih dari 10 tahun, kami yakin dapat membantu kegiatan pemasaran perusahaan anda seperti memeriahkan event sehingga mendapatkan perhatian dari {potentialCustomer} anda.",
    p4: "Ketika sudah mencapai kondisi telah mendapatkan perhatian, maka akan sangat lebih mudah bagi Anda untuk menyampaikan informasi yang ingin anda sampaikan kepada {potentialCustomer} anda.",
    p5: "Silahkan berkunjung ke lokasi workshop kami yang berada di Bandung untuk melihat langsung bagaimana dari awal sampai akhir proses pembuatan kostum badut maskot berlangsung.",
    p6: "Tak lupa menyebutkan bahwa kami hingga bisa melayani masyarakat sampai saat ini, tak pelak karena kami senantiasa dibantu oleh relasi supplier-supplier kami yang berkualitas tinggi 🙏",
  },
  contactUs: "Hubungi kami",
  ifThereIsSomethingElse:
    "Apabila ada yang ingin ditanyakan, silahkan hubungi kami via",
  ourPostalAddress: "Alamat pos kami ada di:",
  invoice: "invoice",
  generalTerms: {
    li3: "Proses pembuatan baru akan dimulai 1 hari setelah DP {downPayment} & desain maskot diterima.",
    li4: {
      shipping: "Pengiriman",
      pickInWorkshop: "Diambil Sendiri",
      youCanPickInWorkshop:
        "Kostum dapat diambil sendiri di workshop Kami yang beralamat di:",
      byCourier: "Dikirim Menggunakan Agen Pengiriman/Ekspedisi",
      air: "udara",
      sea: "laut",
      land: "darat",
      jabodetabek: "Jabodetabek",
      sumatera: "Sumatera, Kalimantan, Sulawesi & Bali",
      borneo: "Kalimantan",
      sulawesi: "Sulawesi",
      papua: "Papua",
      lainnya: "Lainnya",
      orByCustomDeal:
        "atau menggunakan jasa pengiriman yang dipilih sesuai kesepakatan bersama.",
    },
    li5: "Persoalan terkait hak cipta desain maskot, sepenuhnya ditanggung oleh klien.",
    li6: "Segala akibat dari perubahan harga {invoice} atas permintaan klien, sepenuhnya ditanggung oleh klien.",
    li7: "Perubahan dapat terjadi sewaktu-waktu tanpa pemberitahuan.",
    li8: "Dengan memesan di sini berarti Anda telah setuju mengikuti syarat & ketentuan yang ada di halaman ini.",
  },
  mascotCostumeTerms: {
    title: "KOSTUM BADUT MASKOT",
    li1: "DP untuk tiap 1 unitnya adalah Rp1.500.000,-.",
    li2: "Umumnya proses pembuatan memakan waktu 10-14 hari kerja saja (Minggu libur).",
    li3: "Kostum maskot yang sudah selesai pengerjaannya namun klien tidak menyelesaikan pembayarannya dalam waktu 30 hari, maka keadaan kostum maskot sudah bukan merupakan tanggung jawab kami.",
    li4: "INDOMASCOT berhak mendisplay (memajang) foto & video kostum maskot hasil pengerjaan untuk tujuan portfolio, kecuali ada permintaan khusus dari klien.",
  },
  customDollTerms: {
    title: "BONEKA SOUVENIR (BONEKA MASKOT KECIL)",
  },
  lastEdited: "Terakhir diedit: ",
  whatIsMascotCostume: "Apa itu kostum badut maskot?",
  accordingToWikipedia:
    "Menurut WikiPedia , kostum badut maskot adalah kostum yang dibuat oleh suatu perusahaan untuk menjadikan nyata maskot/logo perusahaan mereka kepada publik. Kostum ini sering digunakan untuk sosialisasi merek, promosi perusahaan, pameran, atau meningkatkan suatu brand awareness tim dalam suatu acara olahraga. Karena akan dipakai oleh manusia, maka desainnya harus menyesuaikan proporsional tubuh manusia.",
  whatIsTheDifferent: "Bedanya dengan baju badut?",
  itsJustTheSame:
    "Sama saja. Hanya sebutannya saja kadang saat ini masyarakat lebih suka menyebutnya baju badut atau kostum badut. Baju badut ini berukuran all-size yang artinya proporsional dipakai oleh orang dewasa yang tinggi umumnya 170-190cm. Untuk desainnya juga bisa diambil dari boneka maskot yang terlebih dahulu disesuaikan dengan proporsional tubuh manusia.",
  yourSuccess: "Keberhasilan Marketing dan Sosialisasi Anda Adalah Misi Kami",
  brandMarketingIsImportant:
    "Marketing suatu brand atau produk adalah sesuatu yang challenging bagi suatu perusahaan. Bahkan, menyampaikan pesan sosialisasi pun terkadang masih sulit tercapai bagi suatu instansi yang sudah dikenal luas oleh masyarakat. Di sini lah kami ada untuk membantu dengan memproduksi kostum badut maskot untuk membantu kesuksesan event marketing anda!",
  qualityMaterial: "Material Berkualitas",
  velboa: "velboa",
  drill: "drill",
  lotto: "lotto",
  furing: "furing",
  toSatisfy:
    "Untuk memuaskan klien, kami hanya menggunakan kain yang berkualitas no {firstGrade} di market. Mulai dari jenis {velboa} (boneka), {drill} hingga {lotto} yang beraneka ragam warna untuk mendapatkan hasil yang mirip dengan desain aslinya. Kain ini tidak panas dan lembut. Selain itu, kami beri tambahan lapisan {furing} agar dapat lebih menyerap keringat.",
  fast: "Pengerjaan Cepat",
  inOurProcess:
    "Dalam proses kami hingga seperti saat ini, kami beberapa kali mendapati klien kami yang terburu-buru karena kostumnya akan dipakai segera, misalnya dalam waktu 2 minggu setelah DP. Maka dari itu, kami mengoptimalkan kecepatan hingga hanya memakan waktu 10 hari kerja saja (Minggu libur).",
  freeBonusHeadFanAndMask: "Bonus Kipas",
  performerIsSweating:
    "{performer} (pemakai) kostum pastinya sangat mudah berkeringat dikarenakan suhu yang hangat di dalam topengnya. Hal ini secara tidak langsung berakibat performer mengalami cepat kelelahan. Maka dari itu, kami berikan bonus {exhaustHead} untuk membantu sirkulasi dalam topeng anda menjadi lebih lancar (gratis).",
  howToOrderMascotCostumeSteps: {
    step0: "Klien",
    step1: "Klien Share Desain",
    step2: "Kami Mengirimkan Surat Penawaran",
    step3: "Klien DP & Konfirmasi",
    step4: {
      0: "Kami Mengirimkan Foto Jadi",
      1: "Foto Hasil Final Kostum Badut Maskot Tampak Depan",
      2: "Foto Hasil Final Kostum Badut Maskot Tampak Samping",
      3: "Foto Hasil Final Kostum Badut Maskot Tampak Belakang",
    },
    step5: "Klien Pelunasan & Konfirmasi",
    step6: "Kami Melakukan Pengiriman",
  },
  keywords: {
    index:
      "kostum badut, badut maskot, kostum maskot, kostum badut maskot, badut lucu, badut gemes, kostum badut perusahaan, kostum badut instansi, kostum badut maskot olahraga",
  },
  pageTitle: {
    mascotCostume: "Portfolio",
    toc: "Syarat & Ketentuan",
    aboutUs: "Tentang Kami",
    customDoll: "Spesialis Boneka Custom (Boneka Maskot Kecil)",
    blog: "Artikel",
  },
  pageDescription: {
    index:
      "INDOMASCOT melayani pembuatan kostum badut maskot untuk branding produk atau instansi anda dengan desain dari anda sendiri. (WA 0822-2155-6633)",
    mascotCostume:
      "Semua kostum badut maskot (custom) dengan desain dari klien sendiri yang telah kami kerjakan ada di sini.",
    toc: "Syarat dan Ketentuan dalam melakukan order kostum badut maskot di kami ada di sini.",
    aboutUs:
      "Kami ingin bersama-sama anda membangun pemasaran anda berhasil dengan kostum badut maskot buatan kami.",
    blog: "INDOMASCOT selalu mempersembahkan berita-berita seputar kostum badut maskot yang bermanfaat untuk anda",
  },
  mascot: "Maskot {mascotName}",
  author: "Penulis: ",
  publishedDate: "Pada Tanggal: ",
  searchResultsFor: "Hasil Pencarian",
  whatsAppNo: "0822-2155-6633",
  topbar: {
    message: {
      closeOrder: "CLOSE ORDER",
      full: "karena sudah penuh sampai {date}",
      openAgain: "・ sampai 8 Mei 2022",
      happyNewYear2022: "Selamat Tahun Baru 2022",
      happyImlek2022: "Selamat Tahun Baru Imlek 2022",
    },
  },
  fitting: "fitting",
  show100more: "lihat lainnya",
  uploadDesign: "Upload Desain",
  backToBlog: "Kembali ke halaman Blog",
  showAll: "Lihat Semua",
  lastUpdated: "Terakhir diupdate: ",
  dollMascotCostume: "Kostum Badut Boneka Maskot {0}",
  fullSizePhoto: "Foto {0} Ukuran Penuh",
  design: "Desain",
  proportional: "Proporsional",
  screenshot: "Screenshot",
  fittingInstruction: {
    upload: {
      useButton: "Gunakan tombol",
      below: "yang ada di bawah ini.",
      beside: "yang ada di samping kiri.",
    },
    proportional: "Siapkan desain maskot yang sudah",
    resizeDragRotate: {
      useHandle: "Perbesar/Perkecil, Geser & Putar",
      ifNeeded: "setelah upload bila perlu.",
    },
    removeBg: "Gunakan background remover tool seperti",
    png: "File harus berextension (berakhiran)",
    screenshot: "lalu kirim kepada kami.",
  },
  apps: "Apps",
  install: "Install",
  iosInstallation: {
    share: "Tap Icon Share atau Bagikan",
    addToHomeScreen: "Tap Add To Home Screen",
    add: "Tap Add atau Tambah",
    voila: "Okey, aplikasi INDOMASCOT sudah berhasil ditambahkan",
  },
  onlyOn: {
    apple: "Hanya di iOS Safari",
    android: "Hanya di Android Chrome",
    chrome: "Hanya di browser Chrome",
  },
  allArticles: "Semua Artikel",
  underConstruction: "Sedang Dalam Pengembangan",
  designFitting: "fitting desain",
  pageNotFound: "Maaf, Halaman Tidak Ada.",
  allHashtags: "Semua Hashtag",
  designBy: "desain oleh",
  designOwnedBy: "Desain maskot di atas milik",
  featuredClients: "Klien Ternama",
  newest: "Terbaru",
  orSee: "atau lihat",
  seeAtGoogleMaps: "lihat di google maps",
  productionTeam: "Tim Produksi",
};
