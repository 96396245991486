
import { directive } from "vue-awesome-swiper";

export default {
  name: "CarouselBadutMaskotLagi",
  directives: {
    swiper: directive,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiperOption: {
        grabCursor: true,
        spaceBetween: 20,
        loop: true,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        autoplay: {
          delay: 3000,
        },
        mousewheel: {
          invert: false,
          forceToAxis: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 3,
          },
          480: {
            slidesPerView: 5,
          },
          600: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 7,
          },
          900: {
            slidesPerView: 9,
          },
          1024: {
            slidesPerView: 9,
          },
          1200: {
            slidesPerView: 9,
          },
        },
      },
    };
  },
};
