import { render, staticRenderFns } from "./IosInstallation.vue?vue&type=template&id=593a86c5&"
import script from "./IosInstallation.vue?vue&type=script&lang=ts&"
export * from "./IosInstallation.vue?vue&type=script&lang=ts&"
import style0 from "./IosInstallation.vue?vue&type=style&index=0&id=593a86c5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.1_mi7kfaeh4nmupiqclubmvzt3ru/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlashyBg: require('/opt/buildhome/repo/components/FlashyBg.vue').default})
