import Vue from "vue";

Vue.mixin({
  methods: {
    createSEOMeta(meta, name, slug) {
      return [
        { hid: "og:title", property: "og:title", content: meta.og_title },
        { hid: "description", name: "description", content: meta.description },
        {
          hid: "og:description",
          property: "og:description",
          content: meta.og_description || meta.description,
        },
        { hid: "og:image", property: "og:image", content: meta.og_image },
        {
          hid: "og:url",
          property: "og:url",
          content: process.env.BASE_URL + "/" + slug + "/",
        },
        {
          hid: "og:site_name",
          name: "og:site_name",
          content: name,
        },
        {
          hid: "og:type",
          name: "og:type",
          content: "website",
        },
        {
          hid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          hid: "twitter:site",
          name: "twitter:site",
          content: "@indomascot",
        },
        {
          hid: "twitter:creator",
          name: "twitter:creator",
          content: "@indomascot",
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: meta.twitter_title || meta.title,
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content: meta.twitter_description || meta.description,
        },
        {
          hid: "twitter:image",
          name: "twitter:image",
          content: meta.twitter_image || meta.og_image || "",
        },
      ];
    },
  },
});
